<template>
  <page-section :reversed="reversed" :header="header">
    <v-row class="wrapper" justify="center">
      <v-col class="event-img--contaner" cols="5" :order="reversed ? 2 : 1">
        <aside :class="['image', reversed && 'image--reversed']">
          <img v-if="image" :src="image" class="event-img"/>
          <div v-else class="placeholder"></div>
           <v-img
              :src="require('@/assets/new/play.png')"
              class="play-btn" 
            />  
        </aside>
      </v-col>

      <v-col class="event-details--container" cols="6" :order="reversed ? 1 : 2">
        <main>
          <div class="item-title" v-html="title"></div>
          <div class="description" v-html="description"></div>
        </main>
        <aside :class="['image', reversed && 'image--reversed']">
          <img v-if="image" :src="image" class="event-img"/>
          <div v-else class="placeholder"></div>
                     <v-img
              :src="require('@/assets/new/play.png')"
              class="play-btn" 
            />  
        </aside>
        <footer class="event-section-actions">
          <slot name="actions" />
        </footer>
      </v-col>

    </v-row>
  </page-section>
</template>

<script>
import PageSection from '../homepage/PageSection';

export default {
  components: {
    PageSection
  },
  props: {
    header: { type: String},
    title: { type: String, required: true },
    subtitle: { type: String },
    image: { type: String },
    reversed: { type: Boolean, default: false },
    description: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>
.item-title{
  font-size: 25px !important;
}
.event-section-actions > * { 
  margin: 1rem;
  
}

.event-section-actions > *:first-child { 
  margin-left: 0;
}

</style>

<style   lang="scss">
.event-section{
  padding:0;
  padding-top:10px!important;
  padding-bottom:10px!important;
  .event-details--container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .orange-btn{
        background-color: #FF3C11!important;
        padding: 0 3em!important;
        max-width: 200px;
        span{
          font-size:13px!important;
          font-weight: 600!important;
          line-height: 21px!important;
          letter-spacing: 1.5555555820465088px!important;

        }
      }
      .image{
        display:none;
      }
  }
}


main{
.title, .subtitle {
  text-transform: uppercase;
  font-weight: bold;
}

.title,
.subtitle,
.description {
  text-align: left;
  margin-top:0!important;
}

.description {
  margin-top: 0.5rem;
}

footer {
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-start;
  align-items: center;
  margin-top:1em;
  z-index: 10;
}

.image {
  position:relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  border-radius:10px!important;

  &--reversed {
    justify-content: flex-end;
  }
  img.event-img{
    width:100%;
  }

  .play-btn{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    cursor:pointer;
    width:75px;
    // opacity:.7;

  }
}

.placeholder {
  width: 300px;
  height: 250px;
  border: solid 2px #444;
}

  .order-2{
    padding-left:3em;
  }

  header{
    margin-bottom:3em!important;
  }
}
@media screen and(max-width:850px) {
    .event{
          margin-bottom: 50px;
    }
    .wrapper{
      flex-direction: column;
      width: 100%!important;
      .event-img--contaner{
        width: 100%;
        flex: 1;
        max-width: 100%;
        margin: 0;
        padding: 0;
        display: none;
      }
      .event-details--container{
          max-width: 100%;
          text-align: center;
          .image{
            display:block;
          }
          .description{
            margin-bottom:15px;
            font-size: 18px!important;
            text-align: center!important;
          }
      }
      footer{
        justify-content: center;
        .orange-btn{
            width: 100%!important;
            max-width: 100%!important;
            margin-right: 0!important;
            height:60px!important;
        }
      }

      .description{
        line-height: 1.5!important;
        font-size: 14px!important;
        letter-spacing: 0!important;
        padding: 0 20px;
        box-sizing: border-box;

      }
    }
}
</style>