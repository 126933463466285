<template>
  <div>
    <page-offset />
    <!-- <top-banner /> -->

    <div class="home-top--banner" fluid :style="windowHeight">
      <v-img :src="require('@/assets/new/home-main.jpg')" contain />
        <div class="home-details">
          <div></div>
                <div class="home-top--details">
                <img v-if="live" :src="require('@/assets/new/live.svg')" class="status-label"/>
                <h1>JOHN WHITMORE</h1>
                <h2>June 21,2022 • 9pm</h2>
              </div>
        </div>

    </div>


  <div class="event-list">
    <template v-for="(event) in events" class="show-item">
        <show-event  :key="event.id"
          :image="event.image"
          class="event event-section"
          :title="event.title"
          :description="event.description"
          id="event"
        >
        <template v-slot:actions>
        <v-btn  class="margin-right--2 orange-btn"  :to="event.url"
          >BUY TICKETS</v-btn
        >
      </template>
        </show-event>
    </template>
  </div>




  </div>
</template>

<script>
// import TopBanner from "../homepage/TopBanner.vue";
import ShowEvent from "./ShowEvent.vue";
import PageOffset from "../layout/PageOffset.vue";

const upcoming = require("@/assets/pickles.png");
export default {
  components: { PageOffset,  ShowEvent },
  data(){
    return{
        title:"PICKLES MONTGOMERY",
        image: upcoming,
        live:true,
        header: `<span class="title--underline">UPCOMING</span> SHOWS`,
        description:"DJ Pickles Montgomery is back to deliver the dance party of the year in VitaVerse! Taking place on Saturday March 12 2022, we move to a wild world of surreal landscapes rooted in 360° live performance. The interactive spectacle will run from 11pm - 2am, broadcasting live across the globe!",
        subtitle: "SATURDAY, March 12 2022",
        url:"/tickets/purchase",
        about1:{
            title:"<span>About</span> VITAVERSE",
            description: `VitaVerse — A trailblazing digital entertainment space created with XR technology, which allows anyone to plug into a virtual concert atmosphere from anywhere in the world. Through the latest in capture render and streaming technologies, each performance connects users to each other, ushering in a new era of events and audience engagement.
                          <br><br>
                          Created by Vita Motus — An innovative multi-disciplinary production design firm driven by artistic passion and grounded in industrial expertise. The VM studio creates ground-breaking concepts, high-tech productions, and inspirational sculptural installations for today’s most influential artists, festivals, and brands.`
        },
        about2:{
            title:"<span>About</span> Grimes",
            description: "Vita Motus is a multi-disciplinary design firm founded in 2006 to create innovative products and environments which forge new paths in the entertainment and design industries. Driven by a balance of artistic passion and industrial design expertise, Vita Motus has extensive experience working in automotive, interactive, & environmental designs, as well as TV and live theater sets, traveling tour stages, and product development and design."
        },
        events:[
          {
            title:"John Whitmore",
            url:"",
            image:require("@/assets/new/event.jpg"),
            description:"More info coming soon"
          },
          {
            title:"John Whitmore",
            url:"",
            image:require("@/assets/new/event.jpg"),
            description:"More info coming soon"
          },
          {
            title:"John Whitmore",
            url:"",
            image:require("@/assets/new/event.jpg"),
            description:"More info coming soon"
          },
          {
            title:"John Whitmore",
            url:"",
            image:require("@/assets/new/event.jpg"),
            description:"More info coming soon"
          },

        ]
    }
  }
};
</script>

<style lang="scss" scoped>

.home-top--banner {
  & > .v-image {
    min-height: 30vh;
    height:50vh;
  }
}
.more-info {
  max-width: 90%;
  margin: auto;
  padding-top: 0!important;
}

.info-entry {
  padding: 2rem;
  max-width: 50%;
  padding-top: 0!important;
}
.info-title {
  font-weight: 500;
  font-size: 50px;
  line-height: 74px;
  letter-spacing: 4.6px;
  text-transform: uppercase;
  margin-bottom: 20px;

  @media screen and(max-width:850px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  @media screen and(max-width:1200px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }

  &::v-deep span {
    font-weight: 200;
  }
}

.info-container{
  background-size:cover;
  margin-bottom: -110px;
  padding-bottom: 110px;
}

.info-description {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 2.7px;

  @media screen and(max-width:850px) {
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    letter-spacing: 2px;
  }

  @media screen and(max-width:1200px) {
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    letter-spacing: 2px;
  }
}

.more-info{
  max-width:100em;
  margin:auto;
}

.home-details{
      .home-top--details {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      left: 55%;
      text-align: left;
      width: 50%;

      div {
        color: #ff3c11;
        font-size: 2em;
        font-size: 32px;
        line-height: 47px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: left;

        & > span {
          margin-top: 4px;
        }
      }
      h1 {
        font-weight: 600;
        font-size: 60px;
        line-height: 1;
        letter-spacing: 1.57px;
        color:#ec542d;
      }
      h2 {
        font-weight: 400;
        font-size: 32px;
        line-height: 1.5;
        letter-spacing: 1.57px;
        text-transform: uppercase;
      }
      .live-icon {
        margin-right: 10px;
      }

      .status-label{
            width:200px;
            max-width:200px!important;
      }

      @media screen and (max-width: 850px) {
        width: 90%;
        left: 20px;
        box-sizing: border-box;
        overflow: hidden;
        bottom: 0px;
        top: auto;
      }

    }
}


.event-list{
  margin-top:3em;
}


.py-6 {
  padding: 100px 0 90px!important;

  @media screen and(max-width:850px) {
    padding: 50px 0!important;
  }
  @media screen and(max-width:120px) {
    padding: 50px 0!important;
  }
}

@media screen and(max-width:850px) {
    .more-info{
        flex-direction:column;
        .info-entry{
          max-width:100%;
        }
    }
    .home-top--banner{
      // margin-top:87px;
    }
}

@media screen and(max-width:1200px) {
    .more-info{
        flex-direction:column;
        .info-entry{
          max-width:100%;
        }
    }


  .home-details .home-top--details {
      width: 100%;
      left: 0;
      text-align: center;
      overflow: hidden;
      bottom: 36px !important;
      top: unset;
      transform: none;

      img{
            margin-bottom: 10px;
            max-width: 150px!important;
      }
      h1{
            font-size: 35px;
      }
      h2{
        font-size: 14px;
      }
      .status-label{
            max-width: 154px!important;
            transform: translateX(23px);
      }
  }

}
</style>
